<template>
<div id="page-sello-facturas">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Sello para Facturas" class="elevation-1 px-5 py-3">
          <v-card-text>
             <v-form ref="form" lazy-validation>
                <v-container grid-list-md justify-center>
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                            <v-text-field label="Recibió:" v-model="model.recibio" :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                            <v-text-field label="Verificó:" v-model="model.verifico" :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row><br>
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                            <v-textarea outlined label="Observaciones:" rows="4" v-model="model.observaciones"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                            <v-autocomplete v-model="model.tipo_plantilla" :items="tipos_plantilla"
                                :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="_id"
                                label="Seleccione Tipo de Plantilla" :rules="[rules.required]">
                            </v-autocomplete>
                        </v-col>
                    </v-row>


                </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" @click.native="generarSello()">
            <v-icon small>print</v-icon> Imprimir
            </v-btn>
        </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
export default {
  components: {
  },
  mounted: function() {
    if (!this.verificaPermiso('t.compras.sellos')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },

  created: function() {
    this.get_plantillas();
    var today = window.moment().format("DD/MM/YYYY HH:mm:ss");
    this.model.fecha = today;
  },
  data() {
    return {
        tipos_plantilla:[],
      model_filters: "",
      fab: [],
      name: "datagrid",
      columns: [
        "nombre",
        "descripcion",
        "estatus",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: ["descripcion", "nombre", "estatus"],
        sortable: ["descripcion", "nombre", "estatus"],
        pagination: {
          infiniteHandler: this.infiniteHandler
        },
        headings: {
          descripcion: "Descripción",
          nombre: "Nombre",
          estatus: "Estatus",
          actions: "Operaciones"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Compras",
          disabled: true,
          href: ""
        },
        {
          text: "Sello para Facturas",
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {
        recibio: "",
        verifico: "",
        observaciones: "",
        fecha:"",
        tipos_plantilla:"",
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {

    generarSello: function() {
      if (this.$refs.form.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');

            window.axios
                .post(process.env.VUE_APP_REPORTES_URL + "/bodeguita/get_sello_factura/", this.model, {
                      "Content-type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      "Allow": "POST",
                      "cache-control": "no-cache",
                      "responseType": "arraybuffer"
                  })
                .then(response => {
                      var bytes = response.data;
                      let blob = new Blob([bytes], {
                              type: response.headers["content-type"]
                          }),
                          url = window.URL.createObjectURL(blob);

                      var a = document.createElement("a");
                      document.body.appendChild(a);
                      a.style = "display: none";
                      a.href = url;
                      a.download = "Sello_"+window.moment().format("DD-MM-YYYY_HH-mm-ss")+".docx";
                      a.click();
                      a.parentNode.removeChild(a);
                })
                .catch(error => {
                  console.log(error);
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al generar el sello. Intente nuevamente.",
                    footer: ""
                  });
                }).then(()=>{
                  window.dialogLoader.hide();
                });

      }

    },
    get_plantillas: function() {

      let data = {
        "selector": {"type":"plantillas", "estatus":"Activo", "tipo_plantilla":"Sello Factura"},
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0){
            response.data.docs.sort(this.ordenar_nombre);
            this.tipos_plantilla = response.data.docs;
          } else
            this.tipos_plantilla = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las plantillas.",
            footer: ""
          });
        });
    },
    ordenar_nombre: function( a, b ){
        if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
            return -1;
        }
        if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
            return 1;
        }
        return 0;
    },


  }
};
</script>
